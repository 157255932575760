/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/display-name */
// import PropTypes from "prop-types";
import React from "react";
import { navigate } from "gatsby";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import querystring from 'querystring';
import { Button, Menu, Dropdown, Icon, Table, Form, Tag, Tabs } from 'antd';
import moment from 'moment';

import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";

import { getFirebase } from '../utils/helpers'

let uiConfig = null;
let firebase = null;

const TabPane = Tabs.TabPane;

const labelToOptions = l => ({ text: l, value: l });

const districtOptions = [
  'Albany',
  'Allentown',
  'Cincinnati',
  'Detroit',
  'Edmonton',
  'Long Island',
  'Los Angeles',
  'Newark',
  'Phoenix',
  'Reno',
  'Rockland',
  'San Francisco Bay Area',
  'Seattle',
  'Toronto',
  'Trenton',
  'Vancouver',
  'Washington DC',
];

const tshirtOptions = [ 'Small', 'Medium', 'Large', 'XL', 'XXL' ].map(labelToOptions);

const handleConfirmPayment = id => {
  firebase.firestore().collection('users').doc(id).set({
    paid: 'venmoConfirmed',
  }, { merge: true })
    .then(() => {
      window.location.reload(true);
    });
}

const handleCashPayment = id => {
  firebase.firestore().collection('users').doc(id).set({
    paid: 'cash',
  }, { merge: true })
    .then(() => {
      window.location.reload(true);
    });
}

const handleCheckPayment = id => {
  firebase.firestore().collection('users').doc(id).set({
    paid: 'check',
  }, { merge: true })
    .then(() => {
      window.location.reload(true);
    });
}

const handleRemovePaid = id => {
  firebase.firestore().collection('users').doc(id).set({
    paid: false,
  }, { merge: true })
    .then(() => {
      window.location.reload(true);
    });
}

function loadUIConfig (firebase) {
  return {
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    // signInFlow: 'popup',
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };
}

const Counter = props => (
  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
    <div style={{ fontSize: '63px', marginRight: '7px' }}>{props.count}</div>
    <div style={{ fontSize: '17px', fontWeight: 'bold' }}>TOTAL<br />{props.label}</div>
  </div>
);

const columns = [{
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
  render: (text, user) => <div>
    <img style={{ width: '50px', verticalAlign: 'middle', marginRight: '15px' }} src={user.photo} />
    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
      <b>{text}</b>{`${user.nickname ? ' (' + user.nickname + ') ' : ''}`}
      <br />
      <i style={{ color: '#adadad' }}>{user.lastUpdated && `Last Updated: ${moment(user.lastUpdated.toDate()).format('M/D h:mma')}`}</i>
      <i style={{ color: '#adadad' }}>{user.paidOn && <div>{`Paid: ${moment(user.paidOn.toDate()).format('M/D h:mma')}`}</div>
      }</i>
    </div>
  </div>,
  sorter: (a, b) => { return a.name.localeCompare(b.name)},
  sortDirections: ['descend', 'ascend'],
  defaultSortOrder: 'ascend',
}, {
  title: 'District',
  dataIndex: 'district',
  key: 'district',
  sorter: (a, b) => { return a.district.localeCompare(b.district)},
  sortDirections: ['descend', 'ascend'],
  filters: districtOptions.map(labelToOptions),
  onFilter: (value, record) => record.district.indexOf(value) === 0,
}, {
  title: 'Phone',
  dataIndex: 'phone',
  key: 'phone',
}, {
  title: 'T-Shirt Size',
  dataIndex: 'tshirt',
  key: 'tshirt',
  sorter: (a, b) => { return a.tshirt.localeCompare(b.tshirt)},
  sortDirections: ['descend', 'ascend'],
  filters: tshirtOptions,
  onFilter: (value, record) => record.tshirt.indexOf(value) === 0,
}, {
  title: 'Ride',
  dataIndex: 'ride',
  key: 'ride',
  sorter: (a, b) => { return a.ride.localeCompare(b.ride)},
  sortDirections: ['descend', 'ascend'],
}, {
  title: 'Allergies',
  dataIndex: 'allergies',
  key: 'allergies',
  sorter: (a, b) => { return a.allergies.localeCompare(b.allergies)},
  sortDirections: ['descend', 'ascend'],
}, {
  title: 'Stay',
  dataIndex: 'stayStart',
  key: 'stayStart',
  render: (text, user) => (
    <span>
      {user.stayStart && `Arrive: ${moment(user.stayStart).format('ddd M/D')}`}
      <br />
      {user.stayEnd && `Depart: ${moment(user.stayEnd).format('ddd M/D')}`}

    </span>
  )
}, {
  title: 'Paid',
  dataIndex: 'paid',
  key: 'paid',
  sorter: (a, b) => { return a.paid.localeCompare(b.paid)},
  sortDirections: ['descend', 'ascend'],
  render: text => {
    let label = 'No';
    let color = 'red';
    if (text === 'venmoUnconfirmed') {
      label = 'Venmo (Unconfirmed)';
      color = 'orange';
    } else if (text === 'venmoConfirmed') {
      label = 'Yes (Venmo)';
      color = 'green';
    } else if (text === 'cash') {
      label = 'Yes (Cash)';
      color = 'green';
    } else if (text === 'check') {
      label = 'Yes (Check)';
      color = 'green';
    } else if (typeof text === 'boolean' && text) {
      label = 'Yes (Square)';
      color = 'green';
    }
    return <Tag color={color}>{label}</Tag>;
  }
}, {
  title: 'Action',
  key: 'action',
  render: (text, user) => {
    const menu = (
      <Menu>
        {(!user.paid || user.paid === 'venmoUnconfirmed') && <Menu.Item>
          <a onClick={() => handleConfirmPayment(user.id)}><Icon type="mobile" /> Venmo</a>
        </Menu.Item>}
        {!user.paid && <Menu.Item><a onClick={() => handleCashPayment(user.id)}><Icon type="dollar" /> Cash</a></Menu.Item>}
        {!user.paid && <Menu.Item><a onClick={() => handleCheckPayment(user.id)}><Icon type="copyright" /> Check</a></Menu.Item>}
      </Menu>
    );

    if (!user.paid || user.paid === 'venmoUnconfirmed') {
      return <Dropdown overlay={menu} trigger={['click']}>
        <a className="ant-dropdown-link" href="#">
          Mark As Paid <Icon type="down" />
        </a>
      </Dropdown>;
    } else {
      return <Button block onClick={() => handleRemovePaid(user.id)}>Mark As Unpaid</Button>
    }
  },
}];

class AdminPage extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      user: null,
      users: [],
      auxies: [],
      loggedIn: false,
      updating: true,
      label: 'PARTICIPANTS',
    }
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleUpdateAndPay = this.handleUpdateAndPay.bind(this);
  }

  handlePayRedirect () {
    if (this.props.location.search.length > 90) {
      const queryData = querystring.parse(this.props.location.search.substr(1));
      const { checkoutId, referenceId, transactionId } = queryData;

      if (transactionId) {
        firebase.firestore().collection('users').doc(this.state.user.uid).set({
          paymentInfo: { checkoutId, referenceId, transactionId },
          paid: true,
        }, { merge: true }).then(() => {
          navigate('/register/');
        });
      }
    }
  }

  componentDidMount () {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const firestore = import('firebase/firestore');

    Promise.all([app, auth, firestore]).then(([fbase]) => {
      firebase = getFirebase(fbase);
      uiConfig = loadUIConfig(firebase);

      this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
        if (!user) return this.setState({ loggedIn: true });
        const { displayName, email, photoURL, uid } = user;

        // check if user is regstered
        // firebase.firestore().collection('users').doc(uid).get().then(doc => {
        //   if (doc.exists) {
        //     this.setState({ registered: doc.data() });
        //   }
        // });
        firebase.firestore().collection('users').get().then(data => {
          // const users = data.map(d => ({ ...d.data() }));
          // console.log(users);
          const users = [];
          const auxies = [];
          data.forEach(doc => {
            const info = doc.data();
            if (info.type === 'auxie') {
              auxies.push({ ...doc.data(), id: doc.id, key: doc.id })
            } else {
              users.push({ ...doc.data(), id: doc.id, key: doc.id })
            }
          });
          this.setState({ users, auxies, updating: false, count: users.length });
        });

        this.setState({
          user: { displayName, email, photoURL, uid },
        });
      });
    });
  }

  componentWillUnmount () {
    this.unregisterAuthObserver();
  }

  handleSignOut () {
    this.setState({ user: null }, _ => firebase.auth().signOut())
  }

  handleUpdateAndPay = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ updating: true });
        firebase.firestore().collection('users').doc(this.state.user.uid).set({
          district: values.district,
          email: values.email,
          name: values.name,
          nickname: values.nickname || '',
          photo: this.state.user.photoURL || '',
          phone: values.phone,
          stayStart: values.stayRange[0].format('LLLL'),
          stayEnd: values.stayRange[1].format('LLLL'),
          allergies: values.allergies || '',
          tshirt: values.tshirt,
        }, { merge: true })
          .then(() => this.setState({ payModal: true, updating: false }))
          .catch(e => console.log('error', e))
      }
    });
  }

  handleUpdate = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ updating: true })
        firebase.firestore().collection('users').doc(this.state.user.uid).set({
          district: values.district,
          email: values.email,
          name: values.name,
          nickname: values.nickname || '',
          photo: this.state.user.photoURL || '',
          phone: values.phone,
          stayStart: values.stayRange[0] ? values.stayRange[0].format('LLLL') : '',
          stayEnd: values.stayRange[1] ? values.stayRange[1].format('LLLL') : '',
          allergies: values.allergies || '',
          tshirt: values.tshirt,
        }, { merge: true })
        .then(() => this.setState({ updating: false }))
        .catch(e => console.log('error', e))
      }
    });
  }

  tabChange = tab => {
    if (tab === '2') {
      this.setState({ count: this.state.auxies.length, label:  'AUXIES' });
    } else {
      this.setState({ count: this.state.users.length, label: 'PARTICIPANTS' });
    }
  }

  render () {

    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <Article width='95%' theme={theme}>
              <header style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Headline title="Admin" theme={theme} margin={'0'} />
                {!!this.state.users.length && <Counter count={this.state.count} label={this.state.label} />}
              </header>
              {!this.state.user
                ? this.state.loggedIn
                  ? (
                    <React.Fragment>
                      <p>Please sign in.</p>
                      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                    </React.Fragment>
                  )
                  : <div> Loading ... </div>
                : !this.state.updating
                  ? (
                  <React.Fragment>
                  <Tabs defaultActiveKey="1" onChange={this.tabChange}>
                    <TabPane tab="Participants" key="1">
                      <Table columns={columns} size="middle" dataSource={this.state.users} pagination={false} />
                    </TabPane>
                    <TabPane tab="Auxies" key="2">
                      <Table columns={columns} size="middle" dataSource={this.state.auxies} pagination={false} />
                    </TabPane>
                    {/* <TabPane tab="Stats" key="3">
                      <Card title="Participants">
                        Total: 1 <br />
                        Paid: 1 Not Paid: 1<br />
                        Total Participants Not Paid: 1
                      </Card>
                      <Card title="Auxies">
                        Total SF Adult Auxies: 1
                      </Card>
                    </TabPane> */}
                  </Tabs>
                  </React.Fragment>
                  )
                  : <div> Loading ... </div>
              }
              {/* --- STYLES --- */}
              <style jsx>{`
                .form {
                  background: transparent;
                }

                :global(.ant-input:hover) {
                  border-color: ${theme.color.brand.primary};
                }
                :global(.ant-input:focus) {
                  border-color: ${theme.color.brand.primary};
                  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
                }

                :global(.ant-btn-primary) {
                  background: ${theme.color.brand.primary};
                  border: 1px solid ${theme.color.brand.primary};
                }
                :global(.ant-btn-primary:hover) {
                  background: ${theme.color.brand.primary};
                  border: 1px solid ${theme.color.brand.primary};
                }

                @from-width desktop {
                  .form :global(input) {
                    max-width: 50%;
                  }
                }
              `}</style>
            </Article>
          )}
        </ThemeContext.Consumer>

      </React.Fragment>
    );
  }

};

const WrappedAdminPage = Form.create({ name: 'register' })(AdminPage);

// RegistrationPage.propTypes = {
//   data: PropTypes.object.isRequired
// };

export default WrappedAdminPage;
